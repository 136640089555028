<script setup lang="ts">
import type DataItem from 'o365.modules.DataObject.Item.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';

import { inject } from 'vue';
import { ODataGridNewRecordCellEditor } from 'o365.components.DataGrid.CellEditor.jsx';

const resolveComponentFromApp = inject('resolveComponent');

const props = withDefaults(defineProps<{
    row: Partial<DataItem>,
    pos: number,
    columns: DataColumn[],
    pinned?: 'left'|'right'|null,
    isLast?: boolean,
    utils: any,
    activeCell?: string,
    rowClassMap?: any
    rowHeight?: number|string
}>(), {
    rowHeight: 34,
});

function isEditable(col) {
    if (typeof col.editable === 'function') {
        return col.editable(props.row);
    } else {
        return col.editable;
    }
};

function selectionClass(colIndex) {

    let className;
    const classMap = props.rowClassMap?.[colIndex];
    if (classMap) {
        className = classMap.join(' ');
    }

    if (props.activeCell && props.activeCell === `${colIndex}_${props.row.index}`) {
        className = (className ? className + ' ' : '') + 'o365-focus-cell';
    }

    return className;
}

</script>

<template>
    <div class="o365-header-row o365-new-record" :class="{'active': row.current, dirty: row.hasChanges && row.disableSaving}" 
        :style="{
            'height': rowHeight+'px',
            'transform': 'translateY('+pos + 'px)'
        }" 
        v-bind="$attrs">
        <template v-for="col in columns" :key="col.colId">
            <template v-if="!col.hide && col.pinned == pinned">
                <div class="o365-body-cell" :class="[selectionClass(col.order), {
                    'o365-body-cell-editable': isEditable(col),
                    }, col.cellClass]" :data-o365-colindex="col.order" :o365-field="col.colId"
                    :style="[{height: rowHeight + 'px' , width: col.width+col.widthAdjustment+'px', left: col.left+'px'}, ...col.cellStyles]">
                    <ODataGridNewRecordCellEditor :ref="`editor_col_${col.order}`" :row="row" :rowHeight="rowHeight" :column="col" :isLast="isLast" :utils="utils" :resolveComponentFromApp="resolveComponentFromApp"/>
                    <i v-if ="row.isNewRecord && col.required && isEditable(col)" class="o365-required-cell-icon"></i>
                </div>
            </template>
        </template>
    </div>
</template>